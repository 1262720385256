import { styled } from "@mui/material";
import { colors, fonts } from "@constants/cssVariables";

export const HeadlineWrapper = styled("div", { shouldForwardProp: (prop) => prop !== "isSponsored" })<{
  isSponsored?: boolean;
  isCampaign?: boolean;
}>(({ theme, isSponsored, isCampaign }) => ({
  paddingTop: isCampaign ? theme.spacing(4) : theme.spacing(8),
  [theme.breakpoints.up("md")]: {
    paddingTop: isCampaign ? theme.spacing(6) : theme.spacing(8),
  },
  "& > * + *": {
    marginTop: theme.spacing(isSponsored ? 2 : 4),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(isSponsored ? 3 : 6),
    },
  },
  ...(!isSponsored && {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(6),
    },
  }),
}));

export const HeadlineTitle = styled("h1")(({ theme }) => ({
  color: colors.neutral90,
  font: fonts.header1,
  wordBreak: "break-word",
  [theme.breakpoints.up("md")]: {
    flexShrink: "0",
  },
}));

export const DateAndCategoryWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(2),
}));

export const Category = styled("p")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: colors.primary,
  font: fonts.category,
  textTransform: "uppercase",
  [theme.breakpoints.up("md")]: {
    paddingBottom: "0px",
  },

  "&:hover": {
    color: colors.primary120,
  },
}));

export const PublishDate = styled("p")(() => ({
  display: "flex",
  alignItems: "center",
  color: colors.neutral40,
  font: fonts.bodySmall,
}));
