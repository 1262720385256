import Image from "next/image";
import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";

export const HeaderImage = styled(Image)(({ theme }) => ({
  objectFit: "cover",
  [theme.breakpoints.up("md")]: {
    borderRadius: 8,
  },
}));

export const ArticleHeaderWrapper = styled(AspectRatioContainer)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: `calc(100% + ${theme.spacing(4 * 2)})`,
    marginLeft: theme.spacing(-4),
  },
}));
