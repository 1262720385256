import React from "react";
import * as StyledArticleCollectionPanels from "@pageContent/ArticleCollectionPanels/ArticleCollectionPanels.styled";
import { PanelSkeleton, PanelSkeletonNumeric } from "@pageContent/ArticleCollectionPanels/ArticlePanel";
import * as StyledArticleContent from "@pageContent/ArticleContent/ArticleContent.styled";
import ArticleContainer from "@pageContent/common/ArticleContainer";
import ImageSkeleton from "@pageContent/common/Skeletons/ImageSkeleton/ImageSkeleton";
import TextSkeleton from "@pageContent/common/Skeletons/TextSkeleton/TextSkeleton";

export const articlePanelSkeletonsTestId = "article-panel-skeletons";

const PageContentSkeleton = ({ shouldRenderArticlePanels }: { shouldRenderArticlePanels?: boolean }) => (
  <ArticleContainer>
    <StyledArticleContent.ArticleGrid>
      <ImageSkeleton aspectRatio="16:9" />
      <TextSkeleton height={[28, 28, 36]} sx={{ marginTop: [8, 8, 10] }} />
      <TextSkeleton height={[28, 28, 36]} sx={{ marginTop: [3, 3, 4] }} width={["100%", "100%", "85%"]} />
      <TextSkeleton height={28} sx={{ marginTop: 3, display: ["block", "block", "none"] }} width={"75%"} />
      <TextSkeleton height={[12, 12, 16]} width={[115, 115, 134]} sx={{ marginTop: [6, 6, 8] }} />
      <TextSkeleton height={[12, 12, 16]} width={[177, 177, 180]} sx={{ marginTop: 3 }} />
      <TextSkeleton height={16} sx={{ marginTop: [5, 5, 8] }} />
      <TextSkeleton height={16} sx={{ marginTop: 3, display: ["block", "block", "none"] }} />
      <TextSkeleton height={16} width={"85%"} sx={{ marginTop: 3 }} />
    </StyledArticleContent.ArticleGrid>
    {shouldRenderArticlePanels && (
      <StyledArticleCollectionPanels.ArticleCollectionPanelsContainer data-testid={articlePanelSkeletonsTestId}>
        <PanelSkeletonNumeric />
        <PanelSkeleton />
      </StyledArticleCollectionPanels.ArticleCollectionPanelsContainer>
    )}
  </ArticleContainer>
);

export default PageContentSkeleton;
