import React, { FC, ReactNode, useState } from "react";
import { COPIED_LINK_TEXT } from "@constants/consts";
import * as Styled from "./Popper.styled";

const Popper: FC<{ children: ReactNode }> = ({ children }) => {
  const [showText, setShowText] = useState(false);

  const handleClick = () => {
    setShowText(true);
  };

  return (
    <Styled.PopperWrapper>
      <Styled.PopperContainer onClick={handleClick}>{children}</Styled.PopperContainer>
      <Styled.PopperText show={showText} onAnimationEnd={() => setShowText(false)}>
        {COPIED_LINK_TEXT}
      </Styled.PopperText>
    </Styled.PopperWrapper>
  );
};

export default Popper;
