import { useEffect, useState } from "react";
import { JwMonitor } from "@4tn/webx-analytics";
import InlinePlayer from "@pageContent/ArticleInlinePlayer/InlinePlayer";
import { FeatureSlugs, VideoReplacementVariants, useFeature } from "@utils/common/featureTooling";
import * as Styled from "./ArticleHeader.styled";

interface ArticleHeaderProps {
  videoGuid?: string;
  imageUrl: string;
  title: string;
  slug: string;
}

const ArticleHeader: React.FC<ArticleHeaderProps> = ({ slug, title, videoGuid, imageUrl }) => {
  const [isConsentAccepted, setIsConsentAccepted] = useState(false);
  const { enabled, variant, variables } = useFeature<{ [slug: string]: string }>(FeatureSlugs.VIDEO_REPLACEMENT);
  const hasAlternativeVideoGuid = enabled && variant === VideoReplacementVariants.REPLACED_VIDEO && !!variables?.[slug];

  useEffect(() => {
    if (videoGuid) JwMonitor.setPlayReason("autostart");

    const handleConsentChange = ({ isConsentModalClosed }: WebXEventParams["consentChange"]) => {
      if (!isConsentModalClosed) return;
      setIsConsentAccepted(true);
      window.eventBus.off("consentChange", handleConsentChange);
    };
    window.eventBus.on("consentChange", handleConsentChange, true);
    return () => {
      window.eventBus.off("consentChange", handleConsentChange);
    };
  }, [videoGuid]);

  if (!videoGuid)
    return (
      <Styled.ArticleHeaderWrapper aspectRatio="16:9">
        <Styled.HeaderImage src={imageUrl} alt={title} fill />
      </Styled.ArticleHeaderWrapper>
    );

  return (
    <Styled.ArticleHeaderWrapper aspectRatio="16:9">
      <InlinePlayer
        title=""
        duration={0}
        videoGuid={hasAlternativeVideoGuid ? variables[slug] : videoGuid}
        imageUrl={imageUrl}
        playerLoadedInitially={isConsentAccepted}
        isFullWidthXs
        enableFloating
      />
    </Styled.ArticleHeaderWrapper>
  );
};

export default ArticleHeader;
