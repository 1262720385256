import { keyframes, styled } from "@mui/material";
import { colors, fonts } from "@constants/cssVariables";

// ~350ms for text to appear and slide-up - 0 - 30%
// ~500ms for text to stay - 30 - 70%
// ~350ms for text to disapear - 70 - 100%
const animation = keyframes`
  0% {
     opacity: 0;
     transform: translateX(-50%) translateY(70%);
  }
  30% {
    opacity: 1;
    transform: translateX(-50%) translateY(-8px);
  }
  70% {
    opacity: 1;
    transform: translateX(-50%) translateY(-8px);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-8px);
  }
`;

export const PopperWrapper = styled("div")({
  position: "relative",
});

export const PopperContainer = styled("div")({
  position: "relative",
  display: "inline-block",
});

export const PopperText = styled("div")<{ show: boolean }>(({ theme, show }) => ({
  font: fonts.buttonSmall,
  color: colors.neutral0,
  backgroundColor: colors.neutral90,
  position: "absolute",
  left: "50%",
  bottom: "100%",
  whiteSpace: "nowrap",
  padding: theme.spacing(2, 3),
  borderRadius: 26,
  visibility: "hidden",
  ...(show && {
    visibility: "visible",
    animation: `${animation} 1200ms forwards`,
  }),
}));
