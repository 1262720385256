import { styled } from "@mui/material";
import * as StyledAdSlot from "../AdSlot/AdSlot.styled";

export const ArticleGrid = styled("div")(({ theme }) => ({
  gridColumn: "12 span",

  [theme.breakpoints.up("lg")]: {
    gridColumnStart: 2,
    gridColumnEnd: 9,
  },

  "> span:first-of-type": {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% + 32px)",
      marginLeft: theme.spacing(-4),
    },
    height: "auto",
    width: "auto",
  },
}));

export const Container = styled("div")(({ theme }) => ({
  [`${StyledAdSlot.Container}`]: {
    gridColumn: "12 span",
    [theme.breakpoints.up("lg")]: {
      gridColumnStart: 2,
      gridColumnEnd: 12,
    },
  },
}));

export const ArticleHeaderWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },
}));
