import React, { useEffect, useMemo, useState } from "react";
import Link from "next/link";
import Tealium from "@4tn/webx-analytics/lib/Tealium";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import getPageCategory from "@utils/common/getPageCategory";
import { getDateLabel, getUpdateStatus } from "@utils/pageContent/dateLabel";
import * as Styled from "./Headline.styled";

export interface HeadlineProps {
  title?: string;
  category?: Category;
  createdAt: string;
  updatedAt: string | null;
  sponsor?: Sponsor;
  hasVideo?: boolean;
  isCampaign?: boolean;
}

const trackClick = ({
  categoryName,
  url,
  sponsored,
  hasVideo,
}: {
  categoryName: string;
  url: string;
  sponsored: boolean;
  hasVideo?: boolean;
}) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
    event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
    event_label: categoryName,
    content_type: getPageCategory({ asPath: url, hasVideo, sponsored }),
    content_category: categoryName,
  });
};

const Headline: React.FC<HeadlineProps> = ({
  title,
  category,
  createdAt,
  updatedAt,
  hasVideo,
  sponsor,
  isCampaign,
}) => {
  const date = useMemo(() => getDateLabel(createdAt), [createdAt]);
  const [updateLabel, setUpdateLabel] = useState("");

  useEffect(() => {
    setUpdateLabel(getUpdateStatus(createdAt, updatedAt));
  }, [createdAt, updatedAt]);

  return (
    <Styled.HeadlineWrapper isSponsored={!!sponsor} isCampaign={isCampaign}>
      {title && <Styled.HeadlineTitle>{title}</Styled.HeadlineTitle>}
      {!isCampaign && (
        <Styled.DateAndCategoryWrapper>
          {!!category && !sponsor && (
            <Link
              href={category.url}
              onClick={() =>
                trackClick({ categoryName: category.title, url: category.url, hasVideo, sponsored: false })
              }
            >
              <Styled.Category>{category.title}</Styled.Category>
            </Link>
          )}
          <Styled.PublishDate>
            {date}
            {updateLabel}
          </Styled.PublishDate>
        </Styled.DateAndCategoryWrapper>
      )}
    </Styled.HeadlineWrapper>
  );
};

export default Headline;
