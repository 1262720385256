import { styled } from "@mui/material";
import { colors, fonts } from "@constants/cssVariables";

export const SocialSharingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  margin: theme.spacing(4, 0),

  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(4),
    margin: theme.spacing(6, 0),
  },
}));

export const SocialSharingTitle = styled("p")(() => ({
  font: fonts.header3,
  color: colors.neutral90,
}));

export const SocialSharingItemsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));
