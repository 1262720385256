import React from "react";
import dynamic from "next/dynamic";
import AdSlot from "@pageContent/AdSlot";
import * as StyledArticleCollectionPanels from "@pageContent/ArticleCollectionPanels/ArticleCollectionPanels.styled";
import { PanelSkeleton, PanelSkeletonNumeric } from "@pageContent/ArticleCollectionPanels/ArticlePanel";
import ArticleHeader from "@pageContent/ArticleHeader";
import Headline from "@pageContent/Headline";
import LinkList from "@pageContent/LinkList";
import PageContentSkeleton from "@pageContent/PageContentSkeleton";
import RichText from "@pageContent/RichText";
import SocialSharing from "@pageContent/SocialSharing";
import SponsoredLabel from "@pageContent/SponsoredLabel";
import ArticleContainer from "@pageContent/common/ArticleContainer";
import { AD_IDS, ScreenSizes } from "@constants/consts";
import { RootNode } from "@typings/richText";
import { FeatureSlugs, SocialShareVariants, useFeature } from "@utils/common/featureTooling";
import useScreenSize from "@utils/common/useScreenSize";
import * as Styled from "./ArticleContent.styled";

const ArticleCollectionPanels = dynamic(() => import("@pageContent/ArticleCollectionPanels"), {
  loading: () => (
    <StyledArticleCollectionPanels.ArticleCollectionPanelsContainer>
      <PanelSkeletonNumeric />
      <PanelSkeleton />
    </StyledArticleCollectionPanels.ArticleCollectionPanelsContainer>
  ),
});

interface ArticleContentProps {
  article?: Article | null;
  isLoading: boolean;
}

const ArticleContent: React.FC<ArticleContentProps> = ({ article, isLoading }) => {
  const screenSize = useScreenSize();
  const { enabled, variant } = useFeature(FeatureSlugs.SOCIAL_SHARE_POSITION);
  const shouldRenderArticlePanels = screenSize === ScreenSizes.lg || screenSize === ScreenSizes.xl;

  if (!article || isLoading) return <PageContentSkeleton shouldRenderArticlePanels={shouldRenderArticlePanels} />;

  const [articleSchema] = Array.isArray(article.structuredData) ? article.structuredData : [article.structuredData];

  return (
    <Styled.Container>
      {screenSize !== ScreenSizes.sm && screenSize !== ScreenSizes.xs && !article.sponsor && screenSize && (
        <ArticleContainer>
          <AdSlot id={AD_IDS.topBanner} />
        </ArticleContainer>
      )}
      <ArticleContainer>
        <Styled.ArticleGrid>
          <ArticleHeader {...article} />
          <Headline {...article} />
          {variant === SocialShareVariants.TOP && <SocialSharing url={articleSchema?.mainEntityOfPage} />}
          {article.sponsor && <SponsoredLabel sponsor={article.sponsor} />}
          <RichText richText={article.content as RootNode} isSponsored={!!article.sponsor} />
          {(!enabled || variant !== SocialShareVariants.TOP) && (
            <SocialSharing
              socialTitle={variant === SocialShareVariants.NO_TITLE ? undefined : article.socialTitle}
              url={articleSchema?.mainEntityOfPage}
            />
          )}
          {screenSize && !article.sponsor && <AdSlot id={AD_IDS.bottomBanner} />}
          {!!article.related.items.length && <LinkList items={article.related.items} title={article.related.title} />}
        </Styled.ArticleGrid>
        {shouldRenderArticlePanels && (
          <ArticleCollectionPanels shouldRenderAdSlot={screenSize === ScreenSizes.xl && !article.sponsor} />
        )}
      </ArticleContainer>
    </Styled.Container>
  );
};

export default ArticleContent;
